<template>
  <div v-if="!fetchingData && data.placeId">
    <v-btn
      :href="`https://search.google.com/local/writereview?placeid=${data.placeId}`"
      color="blue"
      target="_blank"
      class="white--text my-2"
      >Prüfen</v-btn
    >
    <br /><span class="caption">
      Place-ID: {{ data.placeId }}<br />
      Latitude: {{ data.latitude }}<br />
      Longitude: {{ data.longitude }}
    </span>
  </div>
</template>

<script>
import apiRequest from "@/mixins/apiRequest";

export default {
  name: "GoogleGeoPlaceChecker",
  components: {},

  data() {
    return {
      fetchingData: false,
      data: [],
    };
  },

  created: function () {
    this.getData(); // call it immediatly
  },

  methods: {
    async getData() {
      this.fetchingData = true;
      let response = await this.getRequest(
        `companies/${this.$route.params.id}/google/geo`
      );
      this.data = response.data.data;
      this.fetchingData = false;
    },
  },
  mixins: [apiRequest],
};
</script>
