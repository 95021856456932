<template>
  <div>
    <v-card>
      <v-app-bar flat color="primary">
        <v-toolbar-title class="title white--text pl-0"
          >Firmeninformation</v-toolbar-title
        >
        <v-spacer></v-spacer>
        <v-btn color="white" icon @click="dialog = true"
          ><v-icon>mdi-pencil</v-icon></v-btn
        >
      </v-app-bar>

      <v-card-title>
        <span>
          {{ data.name }}<br />
          <span class="caption"> ID: {{ data.id }} </span><br />
          <span class="caption">
            Erstellt: {{ formatDateTime(data.created) }}
          </span>
        </span>
      </v-card-title>

      <v-divider></v-divider>

      <v-card-text>
        <div v-if="data" class="p-4">
          <p>
            <strong>Status:</strong> {{ data.status }}<br />
            <strong>Branche:</strong> {{ data.businessCategory }}<br />
          </p>

          <p v-if="data.isBlocked !== '0'">Der Kunde ist temporär blockiert!</p>

          <h3 class="mb-2">Adresse</h3>
          <p>
            {{ data.name }}<br />
            {{ data.street }} {{ data.streetNumber }} <br />
            {{ data.zipCode }} {{ data.town }}, {{ data.country }}
          </p>
          <h3 class="mb-2">Kontakt</h3>
          <p>
            Telefon: {{ data.phone }}<br />
            Fax: {{ data.fax }}<br />
            E-Mail: {{ data.email }}<br />
            Web: {{ data.url }}
          </p>
        </div>
      </v-card-text>
    </v-card>

    <div v-if="dialog">
      <v-dialog v-model="dialog" persistent scrollable max-width="800px">
        <v-card class="grey lighten-4">
          <v-app-bar flat color="primary">
            <v-toolbar-title class="title white--text pl-0"
              >Basisdaten bearbeiten</v-toolbar-title
            >
            <v-spacer></v-spacer>
            <v-btn color="white" icon @click="closeDialog"
              ><v-icon>mdi-close</v-icon></v-btn
            >
          </v-app-bar>

          <v-card-text>
            <v-container>
              <v-card class="my-2">
                <v-card-title>Firma</v-card-title>
                <v-card-text>
                  <Patcher
                    :itemValue="data.name"
                    itemName="name"
                    itemLabel="Name"
                    :hideLabel="false"
                    :route="patchRoute"
                    inputElement="input"
                    inputType="text"
                    @emit="data.name = $event.value"
                  ></Patcher>

                  <Patcher
                    :itemValue="data.legalName"
                    itemName="legalName"
                    itemLabel="Firmenname"
                    :hideLabel="false"
                    :route="patchRoute"
                    inputElement="input"
                    inputType="text"
                    @emit="data.legalName = $event.value"
                  ></Patcher>

                  <Patcher
                    :itemValue="data.legalForm"
                    itemName="legalForm"
                    itemLabel="Rechtsform"
                    :hideLabel="false"
                    :route="patchRoute"
                    inputElement="select"
                    inputType=""
                    :selectOptions="selectOptions.legalForms"
                    @emit="data.legalForm = $event.value"
                  ></Patcher>

                  <Patcher
                    v-if="data.legalForm === 'gmbhcokg'"
                    :itemValue="data.complementaryCompany"
                    itemName="complementaryCompany"
                    itemLabel="Komplementärin"
                    :route="patchRoute"
                    inputElement="textarea-md"
                    inputType=""
                    @emit="data.complementaryCompany = $event.value"
                  ></Patcher>

                  <Patcher
                    :itemValue="data.businessCategory"
                    itemName="businessCategory"
                    itemLabel="Branche"
                    :hideLabel="false"
                    :route="patchRoute"
                    inputElement="select"
                    inputType=""
                    :selectOptions="selectOptions.businessCategory"
                    @emit="data.businessCategory = $event.value"
                  ></Patcher>

                  <Patcher
                    :itemValue="data.currencyCode"
                    itemName="currencyCode"
                    itemLabel="Währung"
                    :hideLabel="false"
                    :route="patchRoute"
                    inputElement="select"
                    inputType=""
                    :selectOptions="[
                      { name: 'Euro', value: 'EUR' },
                      { name: 'Schweizer Franken', value: 'CHF' },
                      { name: 'US Dollar', value: 'USD' },
                      { name: 'Britisches Pfund', value: 'GBP' },
                      { name: 'Türkische Lira', value: 'TRY' },
                      { name: 'Polnischer Zloty', value: 'PLN' },
                      { name: 'Russischer Rubel', value: 'RUB' },
                      { name: 'Japanischer Yen', value: 'JPY' },
                      { name: 'Chinesischer Yuan', value: 'CNY' },
                      { name: 'Indische Rupie', value: 'INR' },
                      { name: 'Australischer Dollar', value: 'AUD' },
                      { name: 'Kanadischer Dollar', value: 'CAD' },
                      { name: 'Neuseeland-Dollar', value: 'NZD' },
                      { name: 'Südafrikanischer Rand', value: 'ZAR' },
                      { name: 'Brasilianischer Real', value: 'BRL' },
                      { name: 'Hongkong-Dollar', value: 'HKD' },
                      { name: 'Singapur-Dollar', value: 'SGD' },
                      { name: 'Thailändischer Baht', value: 'THB' },
                      { name: 'Tschechische Krone', value: 'CZK' },
                      { name: 'Dänische Krone', value: 'DKK' },
                      { name: 'Ungarischer Forint', value: 'HUF' },
                      { name: 'Israelischer Schekel', value: 'ILS' },
                      { name: 'Malaysischer Ringgit', value: 'MYR' },
                      { name: 'Mexikanischer Peso', value: 'MXN' },
                      { name: 'Norwegische Krone', value: 'NOK' },
                      { name: 'Philippinischer Peso', value: 'PHP' },
                      { name: 'Schwedische Krone', value: 'SEK' },
                      { name: 'Neuer Taiwan-Dollar', value: 'TWD' },
                      { name: 'Vietnamesischer Dong', value: 'VND' },
                      { name: 'Ägyptisches Pfund', value: 'EGP' },
                      { name: 'Kroatische Kuna', value: 'HRK' },
                      { name: 'Bulgarischer Lew', value: 'BGN' },
                      { name: 'Rumänischer Leu', value: 'RON' },
                      { name: 'Südkoreanischer Won', value: 'KRW' },
                      { name: 'Saudi-Rial', value: 'SAR' },
                      { name: 'Venezolanischer Bolívar', value: 'VEF' },
                      { name: 'Argentinischer Peso', value: 'ARS' },
                      { name: 'Kolumbianischer Peso', value: 'COP' },
                      { name: 'Indonesische Rupiah', value: 'IDR' },
                      { name: 'Katar-Riyal', value: 'QAR' },
                      { name: 'Nigerianischer Naira', value: 'NGN' },
                      { name: 'Pakistanische Rupie', value: 'PKR' },
                      { name: 'Rial Oman', value: 'OMR' },
                      { name: 'Kuwait-Dinar', value: 'KWD' },
                      { name: 'Costa-Rica-Colón', value: 'CRC' },
                      { name: 'Dominikanischer Peso', value: 'DOP' },
                      { name: 'Marokkanischer Dirham', value: 'MAD' },
                      { name: 'Tunesischer Dinar', value: 'TND' },
                      { name: 'Ukrainische Hrywnja', value: 'UAH' },
                      { name: 'Chilenischer Peso', value: 'CLP' },
                      { name: 'Kazachstan Tenge', value: 'KZT' },
                      { name: 'Peruanischer Sol', value: 'PEN' },
                      { name: 'Türkische Lira', value: 'TRY' },
                      { name: 'Ungarischer Forint', value: 'HUF' },
                      { name: 'Indische Rupie', value: 'INR' },
                      { name: 'Israelischer Schekel', value: 'ILS' },
                      { name: 'Malaysischer Ringgit', value: 'MYR' },
                      { name: 'Mexikanischer Peso', value: 'MXN' },
                    ]"
                    @emit="data.currencyCode = $event.value"
                  ></Patcher>
                </v-card-text>
              </v-card>

              <v-card class="my-2">
                <v-card-title>Rechtliches</v-card-title>
                <v-card-text>
                  <Patcher
                    :itemValue="data.taxId"
                    itemName="taxId"
                    itemLabel="Umsatzsteuer-Identifikationsnummer"
                    :hideLabel="false"
                    :route="patchRoute"
                    inputElement="input"
                    inputType="text"
                    @emit="data.taxId = $event.value"
                  ></Patcher>

                  <Patcher
                    :itemValue="data.registrationCourt"
                    itemName="registrationCourt"
                    itemLabel="Registergericht"
                    :hideLabel="false"
                    :route="patchRoute"
                    inputElement="input"
                    inputType="text"
                    @emit="data.registrationCourt = $event.value"
                  ></Patcher>

                  <Patcher
                    :itemValue="data.commercialRegisterNumber"
                    itemName="commercialRegisterNumber"
                    itemLabel="Handelsregisternummer"
                    :hideLabel="false"
                    :route="patchRoute"
                    inputElement="input"
                    inputType="text"
                    @emit="data.commercialRegisterNumber = $event.value"
                  ></Patcher>

                  <Patcher
                    :itemValue="data.chamber"
                    itemName="chamber"
                    itemLabel="Kammer"
                    :route="patchRoute"
                    inputElement="textarea-md"
                    inputType=""
                    @emit="data.chamber = $event.value"
                  ></Patcher>

                  <Patcher
                    :itemValue="data.imprintAdditive"
                    itemName="imprintAdditive"
                    itemLabel="Zusatzangaben, Impressum"
                    hint="Kann verwendet werden, um zusätzliche Angaben im Impressum zu hinterlegen."
                    :route="patchRoute"
                    inputElement="textarea-md"
                    inputType=""
                    @emit="data.imprintAdditive = $event.value"
                  ></Patcher>
                </v-card-text>
              </v-card>

              <v-card class="my-2">
                <v-card-title>Adresse</v-card-title>
                <v-card-text>
                  <Patcher
                    :itemValue="data.street"
                    itemName="street"
                    itemLabel="Straße"
                    :hideLabel="false"
                    :route="patchRoute"
                    inputElement="input"
                    inputType="text"
                    @emit="data.street = $event.value"
                  ></Patcher>

                  <Patcher
                    :itemValue="data.streetNumber"
                    itemName="streetNumber"
                    itemLabel="Hausnummer"
                    :hideLabel="false"
                    :route="patchRoute"
                    inputElement="input"
                    inputType="text"
                    @emit="data.streetNumber = $event.value"
                  ></Patcher>

                  <Patcher
                    :itemValue="data.zipCode"
                    itemName="zipCode"
                    itemLabel="Postleitzahl"
                    :hideLabel="false"
                    :route="patchRoute"
                    inputElement="input"
                    inputType="text"
                    @emit="data.zipCode = $event.value"
                  ></Patcher>

                  <Patcher
                    :itemValue="data.town"
                    itemName="town"
                    itemLabel="Ort"
                    :hideLabel="false"
                    :route="patchRoute"
                    inputElement="input"
                    inputType="text"
                    @emit="data.town = $event.value"
                  ></Patcher>

                  <Patcher
                    :itemValue="data.country"
                    itemName="country"
                    itemLabel="Land"
                    :hideLabel="false"
                    :route="patchRoute"
                    inputElement="select"
                    inputType=""
                    selectSet="countries"
                    :selectOptions="selectOptions.countries"
                    @emit="data.country = $event.value"
                  ></Patcher>
                </v-card-text>
              </v-card>

              <v-card class="my-2">
                <v-card-title>Kontakt</v-card-title>
                <v-card-text>
                  <Patcher
                    :itemValue="data.email"
                    itemName="email"
                    itemLabel="E-Mail Adresse"
                    :hideLabel="false"
                    :route="patchRoute"
                    inputElement="input"
                    inputType="email"
                    @emit="data.email = $event.value"
                  ></Patcher>

                  <Patcher
                    :itemValue="data.emailDataProtection"
                    itemName="emailDataProtection"
                    itemLabel="E-Mail Adresse für Datenschutzfragen"
                    :hideLabel="false"
                    :route="patchRoute"
                    inputElement="input"
                    inputType="email"
                    @emit="data.emailDataProtection = $event.value"
                  ></Patcher>

                  <Patcher
                    :itemValue="data.phone"
                    itemName="phone"
                    itemLabel="Telefon"
                    :hideLabel="false"
                    :route="patchRoute"
                    inputElement="input"
                    inputType="text"
                    @emit="data.phone = $event.value"
                  ></Patcher>

                  <Patcher
                    :itemValue="data.fax"
                    itemName="fax"
                    itemLabel="Fax"
                    :hideLabel="false"
                    :route="patchRoute"
                    inputElement="input"
                    inputType="text"
                    @emit="data.fax = $event.value"
                  ></Patcher>

                  <Patcher
                    :itemValue="data.url"
                    itemName="url"
                    itemLabel="Webseite URL"
                    :hideLabel="false"
                    :route="patchRoute"
                    inputElement="input"
                    inputType="url"
                    @emit="data.url = $event.value"
                  ></Patcher>
                </v-card-text>
              </v-card>

              <v-card class="my-2">
                <v-card-title>Büroadresse (Optional)</v-card-title>
                <v-card-text>
                  <p>
                    Bitte nur eintragen, wenn eine abweichende Anschrift
                    existiert.
                  </p>
                  <Patcher
                    :itemValue="data.officeAddressStreet"
                    itemName="officeAddressStreet"
                    itemLabel="Straße"
                    :hideLabel="false"
                    :route="patchRoute"
                    inputElement="input"
                    inputType="text"
                    @emit="data.officeAddressStreet = $event.value"
                  ></Patcher>

                  <Patcher
                    :itemValue="data.officeAddressStreetNumber"
                    itemName="officeAddressStreetNumber"
                    itemLabel="Hausnummer"
                    :hideLabel="false"
                    :route="patchRoute"
                    inputElement="input"
                    inputType="text"
                    @emit="data.officeAddressStreetNumber = $event.value"
                  ></Patcher>

                  <Patcher
                    :itemValue="data.officeAddressZip"
                    itemName="officeAddressZip"
                    itemLabel="Postleitzahl"
                    :hideLabel="false"
                    :route="patchRoute"
                    inputElement="input"
                    inputType="text"
                    @emit="data.officeAddressZip = $event.value"
                  ></Patcher>

                  <Patcher
                    :itemValue="data.officeAddressTown"
                    itemName="officeAddressTown"
                    itemLabel="Ort"
                    :hideLabel="false"
                    :route="patchRoute"
                    inputElement="input"
                    inputType="text"
                    @emit="data.officeAddressTown = $event.value"
                  ></Patcher>

                  <Patcher
                    :itemValue="data.officeAddressEmail"
                    itemName="officeAddressEmail"
                    itemLabel="E-Mail Adresse"
                    :hideLabel="false"
                    :route="patchRoute"
                    inputElement="input"
                    inputType="email"
                    @emit="data.officeAddressEmail = $event.value"
                  ></Patcher>

                  <Patcher
                    :itemValue="data.officeAddressPhone"
                    itemName="officeAddressPhone"
                    itemLabel="Telefon"
                    :hideLabel="false"
                    :route="patchRoute"
                    inputElement="input"
                    inputType="text"
                    @emit="data.officeAddressPhone = $event.value"
                  ></Patcher>

                  <Patcher
                    :itemValue="data.officeAddressFax"
                    itemName="officeAddressFax"
                    itemLabel="Fax"
                    :hideLabel="false"
                    :route="patchRoute"
                    inputElement="input"
                    inputType="text"
                    @emit="data.officeAddressFax = $event.value"
                  ></Patcher>
                </v-card-text>
              </v-card>

              <v-card class="my-2">
                <v-card-title>Einstellungen</v-card-title>
                <v-card-text>
                  <Patcher
                    :itemValue="data.status"
                    itemName="status"
                    itemLabel="Status"
                    :hideLabel="false"
                    :route="patchRoute"
                    inputElement="select"
                    inputType=""
                    :selectOptions="selectOptions.status"
                    @emit="data.status = $event.value"
                  ></Patcher>

                  <Patcher
                    :itemValue="data.isLadiesOnly"
                    itemName="isLadiesOnly"
                    itemLabel="Ladies only"
                    :hideLabel="false"
                    :route="patchRoute"
                    inputElement="switch"
                    inputType=""
                    @emit="data.isLadiesOnly = $event.value"
                  ></Patcher>

                  <Patcher
                    :itemValue="data.isEmsXBody"
                    itemName="isEmsXBody"
                    itemLabel="EMS X-Body"
                    :hideLabel="false"
                    :route="patchRoute"
                    inputElement="switch"
                    inputType=""
                    @emit="data.isEmsXBody = $event.value"
                  ></Patcher>

                  <Patcher
                    :itemValue="data.isBlocked"
                    itemName="isBlocked"
                    itemLabel="Kunde ist blockiert"
                    :hideLabel="false"
                    :route="patchRoute"
                    inputElement="switch"
                    inputType=""
                    switchLabel="Temporär blockiert"
                    @emit="data.isBlocked = $event.value"
                  ></Patcher>

                  <h3 class="mt-4 mb-2">Google Place-ID</h3>
                  <GoogleGeoPlaceChecker />
                </v-card-text>
              </v-card>
            </v-container>
          </v-card-text>
        </v-card>
      </v-dialog>
    </div>
  </div>
</template>

<script>
import apiRequest from "@/mixins/apiRequest";
import helpers from "@/mixins/helpers";
import Patcher from "@/components/ui/Patcher.vue";
import GoogleGeoPlaceChecker from "@/components/companies/GoogleGeoPlaceChecker.vue";
export default {
  name: "BaseinfoCard",

  components: {
    Patcher,
    GoogleGeoPlaceChecker,
  },

  data() {
    return {
      title: "",
      preloader: false,
      dialog: false,
      data: [],
      patchRoute: "companies/" + this.$route.params.id,
      status: "Aktiv",
      /* Die selectOptions werden als PROP in die Patcher Componente übergeben.
      Dort werden diese zur Auswahl gestellt. */
      selectOptions: {
        legalForms: [
          { name: "GmbH", value: "gmbh" },
          { name: "GbR", value: "gbr" },
        ],
        countries: [
          { name: "Deutschland", value: "DE" },
          { name: "Österreich", value: "AT" },
          { name: "Schweiz", value: "SZ" },
          { name: "Niederlande", value: "NL" },
          { name: "Belgien", value: "BE" },
        ],
        gender: [
          { name: "Weiblich", value: "female" },
          { name: "Männlich", value: "male" },
          { name: "Divers", value: "diverse" },
        ],
        yesNo: [
          { name: "Nein", value: 0 },
          { name: "Ja", value: 1 },
        ],
        status: [
          { name: "Offen", value: "open" },
          { name: "Aktiv", value: "active" },
          { name: "Ruht", value: "sleeping" },
          { name: "Inaktiv", value: "inactive" },
        ],
        businessCategory: [
          { name: "Fitness", value: "fitness" },
          { name: "Golf", value: "golf" },
          { name: "Physio", value: "physio" },
          { name: "EMS", value: "ems" },
          { name: "Sonstige", value: "other" },
        ],
      },
    };
  },

  created: function () {
    this.getData(); // call it immediatly
  },

  methods: {
    emittedAction(value) {
      if (value.action === "itemPatched") {
        this.getData(); // reload Data
      }
    },

    closeDialog() {
      this.dialog = false;
    },

    async getData() {
      this.preloader = true;

      let response = await this.getRequest(
        "companies/" + this.$route.params.id
      );
      this.data = response.data.data[0];
      this.title = response.data.data[0].name;

      let responsebusinessCategorys = await this.getRequest(
        "products/branches"
      );
      if (responsebusinessCategorys.status === 200) {
        this.selectOptions.businessCategory =
          responsebusinessCategorys.data.data;
      }

      let responseCountries = await this.getRequest(
        "countries?filter[mostWanted]"
      );
      if (responseCountries.status === 200) {
        this.selectOptions.countries = responseCountries.data.data;
      }

      let responseLegalForms = await this.getRequest("companiesLegalForms ");
      if (responseLegalForms.status === 200) {
        this.selectOptions.legalForms = responseLegalForms.data.data;
      }

      this.preloader = false;
    },
  },

  computed: {
    rewrittenItem: function () {
      /* Umschreiben bestimmter Werte direkt im Array.
        1/0 wird Ja/Nein etc. */

      let data = this.data;

      if (!data.businessField) {
        data.businessField = "-";
      }

      if (data.status === "active") {
        data.statusText = "Aktiv";
      }

      return data;
    },
  },

  mixins: [apiRequest, helpers],
};
</script>
