<template>
  <div id="page">
    <PageHeader
      :pageTitle="'Kunde: ' + clientName"
      :pageDescription="pageDescription"
    ></PageHeader>

    <v-row>
      <v-col cols="8" class="ml-auto">
        <v-row dense>
          <v-col class="col-12">
            <BaseinfoCard></BaseinfoCard>
          </v-col>
          <v-spacer></v-spacer>
          <v-col class="col-12">
            <!-- global component -->
            <Notes
              :route="`/companies/${this.$route.params.id}/notes`"
              :key="notesKey"
            />
          </v-col>
        </v-row>
      </v-col>

      <v-col cols="4">
        <Subnavigation
          root="/csm/company-manager/:id"
          :pageTitle="pageTitle"
        ></Subnavigation>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import apiRequest from "@/mixins/apiRequest";
import PageHeader from "@/components/ui/PageHeader.vue";
import Subnavigation from "@/components/ui/Subnavigation.vue";
import BaseinfoCard from "@/components/companies/BaseinfoCard.vue";
import Notes from "@/components/global/notes/Notes.vue";
/* import ActivityCard from "@/components/ui/ActivityCard.vue"; */
/* import SetupStatusCard from "@/components/companies/SetupStatusCard.vue";
import CampaignsCard from "@/components/companies/CampaignsCard.vue"; */
/* import PersonsCard from "@/components/companies/PersonsCard.vue";
import AdminsCard from "@/components/companies/AdminsCard.vue"; */
export default {
  name: "Companydetails",

  components: {
    PageHeader,
    Subnavigation,
    BaseinfoCard,
    Notes,
    /* ActivityCard, */
    /* SetupStatusCard,
    CampaignsCard, */
    /* PersonsCard,
    AdminsCard, */
  },

  data() {
    return {
      pageTitle: "Kunde:",
      pageDescription: "",
      title: "",
      preloader: false,
      data: [],
      clientName: null,
      notesKey: 0,
    };
  },

  created: function () {
    this.getData(); // call it immediatly
  },

  methods: {
    async getData() {
      this.preloader = true;

      /* USEING REUSEABLE MIXIN METHOD */
      let response = await this.getRequest(
        "companies/" + this.$route.params.id
      ); // await data from mixin
      this.preloader = false;

      this.data = response.data.data[0];
      this.clientName = this.data.name;
    },
  },

  mixins: [apiRequest],
};
</script>
